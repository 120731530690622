import { OCPP_API } from "./common/http-common";

const tenant = process.env.VUE_APP_TENANT;

export function getIdTagsGroupedByCustomer() {
  return OCPP_API.get(`/${tenant}/assets/v1.0/tenants/${tenant}/customeridtag`);
}

export function addTagForCustomer(customerExternalId, tag) {
  return OCPP_API.put(
    `/${tenant}/assets/v1.0/tenants/${tenant}/customeridtag/${customerExternalId}`,
    tag
  );
}

export function deleteTagsForCustomer(customerExternalId, idTags) {
  return OCPP_API.delete(
    `/${tenant}/assets/v1.0/tenants/${tenant}/customeridtag/${customerExternalId}`,
    {
      data: {
        idTags: idTags,
      },
    }
  );
}
