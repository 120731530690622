import { render, staticRenderFns } from "./RfidCardsView.vue?vue&type=template&id=550d5fff&scoped=true&"
import script from "./RfidCardsView.vue?vue&type=script&lang=js&"
export * from "./RfidCardsView.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/energia.min.css?vue&type=style&index=0&id=550d5fff&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "550d5fff",
  null
  
)

export default component.exports