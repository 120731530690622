<template>
  <div>
    <div class="text-center mt-3" v-if="isCardsLoading">
      <b-spinner />
    </div>
    <div v-else>
      <b-form-input
        id="filter-input"
        v-model="filter"
        @input="filterRows"
        type="search"
        placeholder="Type to Search"
      ></b-form-input>

      <b-table-simple responsive class="tbl text-center mt-3">
        <b-thead>
          <b-tr>
            <b-th class="text-left font-weight-bold">customerId</b-th>
            <b-th class="font-weight-bold">Virtual RFIDs</b-th>
            <b-th class="font-weight-bold">Real RFIDs</b-th>
            <b-th />
            <b-th />
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="customer in idTagsList" :key="customer.customerId">
            <b-td class="w-25 text-left">{{ customer.customerId }}</b-td>
            <b-td>
              <v-chip
                class="m-1"
                small
                v-for="tag in getIdTagsByIdTagType(customer.idTags, 'VIRTUAL')"
                :key="tag.token"
              >
                {{ tag.token }}
              </v-chip>
            </b-td>
            <b-td>
              <v-chip
                class="m-1"
                small
                v-for="tag in getIdTagsByIdTagType(customer.idTags, 'REAL')"
                :key="tag.token"
              >
                {{ tag.token }}
              </v-chip>
            </b-td>
            <b-td
              class="clickCursor"
              @click="showModal('deleteModal', customer.customerId)"
            >
              <font-awesome-icon icon="trash" />
            </b-td>
            <b-td
              class="clickCursor"
              @click="showModal('addModal', customer.customerId)"
            >
              <font-awesome-icon icon="plus" />
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>

    <b-modal
      ref="addModal"
      id="addModal"
      :title="`Customer ${selectedCustomerId}`"
      hide-footer
    >
      <div>
        <b-form @submit="addRfid" v-if="show">
          <div>
            <div class="my-3">
              <b-form-group
                id="fieldset-1"
                description="Tags must be 3 to 20 characters in length"
                label="Add RFID tag"
                label-for="addTag"
                :state="addTagState"
              >
                <b-form-input
                  id="addTag"
                  v-model="addTagInput"
                  :state="addTagState"
                  trim
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="mt-4">
              <b-button class="btn--primary addBtn" type="submit">
                Add
              </b-button>
              <b-button
                class="text-success ml-1 border border-success addBtn"
                @click="closeModal('addModal')"
              >
                Cancel
              </b-button>
            </div>
          </div>
        </b-form>
      </div>
    </b-modal>

    <b-modal
      ref="deleteModal"
      id="deleteModal"
      :title="`Customer ${selectedCustomerId}`"
      hide-footer
    >
      <div>
        <b-form @submit="removeRfids" v-if="show">
          <div>
            <div class="my-3">
              <div>Real RFID tags</div>
              <v-chip
                v-for="tag in getNotRemovedSelectedTags()"
                :key="tag"
                class="m-1"
                small
                close
                @click:close="deletedRealIdTags.push(tag)"
              >
                {{ tag }}
              </v-chip>
            </div>

            <div class="my-3" v-if="deletedRealIdTags.length > 0">
              <div>Tags to be removed</div>
              <v-chip
                v-for="tag in deletedRealIdTags"
                :key="tag"
                class="m-1"
                small
                @click="addBackRemovedTagFromDeleted(tag)"
              >
                {{ tag }}
              </v-chip>
            </div>

            <div class="mt-4">
              <b-button
                class="btn--primary addBtn"
                type="submit"
                :disabled="deletedRealIdTags.length < 1"
              >
                Confirm
              </b-button>
              <b-button
                class="text-success ml-1 border border-success addBtn"
                @click="closeModal('deleteModal')"
              >
                Cancel
              </b-button>
            </div>
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  addTagForCustomer,
  getIdTagsGroupedByCustomer,
  deleteTagsForCustomer,
} from "../api/IdTagsApi";
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTbody,
  BTd,
  BSpinner,
  BButton,
  BModal,
  BForm,
} from "bootstrap-vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLink,
  faTrash,
  faCheck,
  faEdit,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faLink, faTrash, faCheck, faEdit, faPlus);

export default {
  components: {
    "b-table-simple": BTableSimple,
    "b-thead": BThead,
    "b-tr": BTr,
    "b-th": BTh,
    "b-tbody": BTbody,
    "b-td": BTd,
    "b-spinner": BSpinner,
    "b-button": BButton,
    "b-modal": BModal,
    "b-form": BForm,
    "font-awesome-icon": FontAwesomeIcon,
  },
  name: "rfidCardsTable",
  data() {
    return {
      isCardsLoading: true,
      originalIdTagsList: null,
      idTagsList: this.getIDTagsList(),
      selectedCustomerId: null,
      addTagInput: "",
      show: true,
      selectedToken: "",
      selectedRealIdTags: [],
      deletedRealIdTags: [],
      filter: "",
    };
  },
  computed: {
    addTagState() {
      return this.addTagInput.length >= 3 && this.addTagInput.length <= 20;
    },
  },
  methods: {
    getIDTagsList() {
      return new Promise((resolve, reject) => {
        const vm = this;
        getIdTagsGroupedByCustomer()
          .then((response) => {
            vm.idTagsList = response.data;
            vm.originalIdTagsList = response.data;
            vm.isCardsLoading = false;
            vm.filterRows(vm.filter);
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    getIdTagsByIdTagType(idTags, tagType) {
      return idTags.filter((t) => t.idtagType === tagType);
    },
    getNotRemovedSelectedTags() {
      return this.selectedRealIdTags.filter(
        (t) => !this.deletedRealIdTags.includes(t)
      );
    },
    resetSelectedAndFormData() {
      this.selectedCustomerId = null;
      this.selectedRealIdTags = [];
      this.deletedRealIdTags = [];
      this.addTagInput = "";
    },
    closeModal(modalName) {
      this.resetSelectedAndFormData();
      this.$refs[modalName].hide();
    },
    showModal(modal, customerId) {
      this.selectedCustomerId = customerId;
      this.selectedRealIdTags = this.originalIdTagsList
        .find((customerTags) => customerTags.customerId === customerId)
        .idTags.filter((tag) => tag.idtagType === "REAL")
        .map((tag) => tag.token);
      this.$refs[modal].show();
    },
    addRfid(bvModalEvent) {
      bvModalEvent.preventDefault();
      if (!this.addTagState) {
        return;
      }
      const vm = this;
      const tag = {
        token: vm.addTagInput,
        externalId: vm.addTagInput,
      };
      addTagForCustomer(vm.selectedCustomerId, tag)
        .catch((err) => {
          const errorData = err.response.data;
          this.$bvToast.toast(errorData.detail, {
            title: "RFID update",
            autoHideDelay: 8000,
            variant: "danger",
          });
          console.log(errorData);
        })
        .finally(() => {
          vm.getIDTagsList().then(() => vm.closeModal("addModal"));
        });
    },
    addBackRemovedTagFromDeleted(tag) {
      const index = this.deletedRealIdTags.indexOf(tag);
      if (index > -1) {
        this.deletedRealIdTags.splice(index, 1);
      }
    },
    removeRfids(bvModalEvent) {
      bvModalEvent.preventDefault();
      const vm = this;
      const tags = vm.deletedRealIdTags.map((t) => {
        return {
          token: t,
          externalId: t,
        };
      });
      deleteTagsForCustomer(vm.selectedCustomerId, tags)
        .catch((err) => {
          const errorData = err.response.data;
          this.$bvToast.toast(errorData.detail, {
            title: "RFID deletion",
            autoHideDelay: 8000,
            variant: "danger",
          });
          console.log(errorData);
        })
        .finally(() => {
          vm.getIDTagsList().then(() => vm.closeModal("deleteModal"));
        });
    },
    filterRows(newVal) {
      const searchValue = newVal.trim();
      if (searchValue === "") {
        this.idTagsList = [...this.originalIdTagsList];
        return;
      }
      this.idTagsList = this.originalIdTagsList.filter((customerTag) =>
        this.extractOnlySearchableValuesAsString(customerTag).includes(
          searchValue.toLowerCase()
        )
      );
    },
    extractOnlySearchableValuesAsString(customerTag) {
      let result = "";
      result += customerTag.customerId;
      result += customerTag.idTags.map((t) => `${t.token}`).join("");
      return result.toLowerCase();
    },
  },
};
</script>

<style scoped src="@/assets/css/energia.min.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.addBtn {
  width: 105px;
  height: 30px;
  font-size: 0.8rem;
  justify-content: center;
}

.clickCursor {
  cursor: pointer;
}
</style>
